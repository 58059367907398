<template>
  <div class="modern-login-wrapper">
    <div class="modern-login-container">
      <h1 class="welcome-message">欢迎注册凌空物语智能科技管理系统</h1>
      <el-form :model="user" :rules="rules" ref="userForm" class="form">
        <el-form-item prop="username">
          <el-input size="large" prefix-icon="el-icon-user-solid" v-model="user.username" placeholder="请输入用户名" class="input-field"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="large" prefix-icon="el-icon-lock" show-password v-model="user.password" placeholder="请输入密码" class="input-field"/>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input size="large" prefix-icon="el-icon-lock" show-password v-model="user.confirmPassword" placeholder="请再次输入您密码" class="input-field" @keydown.enter="register"/>
        </el-form-item>

        <el-form-item prop="email">
          <el-input size="medium" prefix-icon="el-icon-message" v-model="user.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input size="small" prefix-icon="el-icon-lock" style="width: 180px" v-model="user.code" placeholder="请输入验证码"></el-input>
          <el-button type="primary" size="medium" class="ml-5" autocomplete="off" @click="sendEmailCode(3)">获取验证码</el-button>
        </el-form-item>

        <el-form-item class="register-buttons">
          <el-button type="primary" size="large" icon="el-icon-circle-check" @click="register" round>确定注册</el-button>
          <el-button type="warning" size="large" @click="$router.push('/login')" round>前往登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register2",
  data() {
    return {
      user: {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      code: '',
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    sendEmailCode(type) {
      let email;
      if (type === 3) {
        email = this.user.email
      }
      if (!email){
        this.$message.error("请输入您的邮箱")
        return
      }
      if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(email)) {
        this.$message.error("请输入正确的邮箱")
        return
      }
      this.request.get("/user/email/" + email + "/" + type).then(res => {
        if (res.code === '200') {
          this.$message.success("验证码发送成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    register() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          if (this.user.password !== this.user.confirmPassword) {
            this.$message.error("两次输入的密码不一致");
            return;
          }
          this.request.post("/user/register", this.user).then(res => {
            if (res.code === '200') {
              this.$message.success("注册成功,登录后请尽快完善个人信息");
              this.$router.push('/login');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.user.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    }
  }
};
</script>

<style scoped>
.modern-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to bottom, #f8f9fa, #53f5d7);
}

.modern-login-container {
  background-color: white;
  width: 460px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-message {
  margin-bottom: 20px;
  color: #343a40;
  font-size: 24px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input-field {
  margin-bottom: 15px;
}

.register-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
